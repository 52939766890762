<template>
  <div class="proposal-overview flex flex-col justify-between min-h-screen">
    <Header />
    <Content class="mb-auto">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span
            class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >Privacy Policy</span
          >
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">
          This Privacy Policy describes how TumorSHOT s.r.o., Lannova 2113/2A,
          Nové Město, 110 00 Praha 1, Czech Republic, Id. No.: 09324925, uses
          and discloses the information we collect from you through this
          website.
        </p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <ol role="list">
          <li>
            What Information Do We Collect?
            <p class="mt-8 text-xl text-gray-500 leading-8">
              We collect information that you voluntarily provide via the
              registration form on this website and/or any other information
              that you provide to TumorSHOT.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              The information submitted via the registration form includes your
              first name, last name, name of your institution, your city and
              state and your email. We may collect further information that you
              provide to us.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              If you submit personal information relating to other people to us
              or to our service providers, you represent that you have the
              authority to do so and to permit us to use the information in
              accordance with this Privacy Policy.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              We may combine the information we collect from you with
              information that we receive about you from other sources, such as
              public databases, providers of demographic information, joint
              marketing partners and other third parties.
            </p>
          </li>

          <li>
            How Do We Use Your Information?
            <p class="mt-8 text-xl text-gray-500 leading-8">
              We process your information to review your application for a
              grant, provide a grant and for communication with you and to
              inform you on status of your application, based on necessity to
              perform an agreement we have with you due to your registration on
              our website and for duration of such agreement.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              We may also use it:
            </p>
            <ul>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  to provide you information about our other grant programs,
                  based on our legitimate interest in promotion of our similar
                  products and services – to this end we keep the data for 2
                  years after our decision about your application;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  to exercise and protect our legitimate claims and rights, e.g.
                  to settle disputes, claim our rights or file a claim or
                  petition with respective public authorities or courts, based
                  on our legitimate interest in protecting our rights and
                  defending us against third party claims – to this end we keep
                  the data for duration of statutory limitation periods and
                  during any ongoing proceedings; and
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  for our business purposes, such as data analysis; audits;
                  monitoring and prevention of fraud, infringement, and other
                  potential misuse of this website, based on our legitimate
                  interest in understanding our business, customers, and
                  partners and on protection of our business and systems – to
                  this end we keep the data for duration of the granted project
                  and following cooperation with you.
                </p>
              </li>
            </ul>
          </li>

          <li>
            When and to Whom Do We Disclose Your Information?
            <p class="mt-8 text-xl text-gray-500 leading-8">
              The information we collect from you and about you may be
              disclosed:
            </p>
            <ul>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  to data processors, being third parties that provide services
                  to us, such as fulfilling requests for information, answering
                  calls, administering programs or projects;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  to other data controllers, as required by law, such as to law
                  enforcement, to health authorities to report possible adverse
                  events, during government inspections or audits, as ordered or
                  directed by courts or other governmental agencies, or in order
                  to comply with a subpoena or other legal process; and
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  to other data controllers being companies from our group,
                  third parties, advisors, and other entities to the extent
                  reasonably necessary for development of or to proceed with the
                  negotiation or completion of a corporate or commercial
                  transaction, including a reorganization, merger, acquisition,
                  joint venture, sale or other disposition of all or a portion
                  of our business, assets, or stock (including in connection
                  with any bankruptcy or similar proceedings).
                </p>
              </li>
            </ul>
          </li>

          <li>
            Your rights
            <p class="mt-8 text-xl text-gray-500 leading-8">
              You have the following rights, subject to conditions prescribed by
              the law:
            </p>
            <ul>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  the right to obtain confirmation whether your personal data
                  are being processed and eventually to access such data;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  the right to update/rectify any personal data related to you;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  the right to erasure of your personal data;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  the right to ask for restriction of the processing of your
                  personal data;
                </p>
              </li>
              <li>
                <p class="mt-8 text-xl text-gray-500 leading-8">
                  the right to data portability.
                </p>
              </li>
            </ul>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              You also have the right to object any processing of your personal
              data that we do based on our legitimate interest.
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              You can exercise your rights by contacting us, preferably at: <img class='p-0 inline' style="margin: 0 !important" src="../assets/tse.svg" />
            </p>
            <p class="mt-8 text-xl text-gray-500 leading-8">
              If you feel we are processing your personal data inappropriately,
              you have the right to lodge a complaint with the respective data
              protection authority, which is the Czech Office for Personal Data
              Protection, Pplk. Sochora 27, 170 00 Praha 7, Czech Republic,
              website: https://www.uoou.cz, e-mail: posta@uoou.cz.
            </p>
          </li>

          <li>
            Miscellaneous
            <p class="mt-8 text-xl text-gray-500 leading-8">
              This Privacy Policy became effective on 1 October 2021. We may
              update it from time to time by posting a new Privacy Policy on
              this website.
            </p>
          </li>
        </ol>
      </div>
    </Content>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header"
import Content from "@/components/Content"
import Footer from "@/components/Footer"

export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Content,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>
